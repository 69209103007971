<template>
  <v-dialog
    v-model="show"
    max-width="500px"
  >
    <v-card>
      <v-toolbar
        flat
        dense
        color="grey lighten-2"
      >
        <v-toolbar-title>
          {{ title }}
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-btn
            icon
            small
            @click="show = false"
          >
            <v-icon
              v-text="'mdi-close'"
            />
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text class="pt-4 text-center">

        <validation-observer ref="observer">
          <v-form>
            <v-container class="pa-0">
              <v-row>
                <v-col
                  cols="12"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    vid="title"
                  >
                    <v-text-field
                      v-model="newItem.title"
                      hide-details="auto"
                      label="Título"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </validation-observer>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="success"
          outlined
          @click="save"
        >
          <v-icon
            left
            v-text="'mdi-content-save'"
          />
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

  export default {

    props: {
      value: {
        type: Boolean,
        default: false,
      },
      item: {
        type: Object,
        default: () => { },
      },
      type: {
        type: String,
        default: 'ROW',
      },
    },

    data () {
      return {
        loading: false,
        questions: [],
        newItem: {
          title: null,
        }
      }
    },

    computed: {
      show: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        },
      },

      title () {
        return this.type === 'ROW' ? 'Linha' : 'Coluna'
      }
    },


    methods: {
      save () {
        let event = this.item ? 'update' : 'insert'

        const item = { ...this.newItem }
        
        this.$emit(event, item, this.type)

        this.show = false

        this.resetItem()
      },

      resetItem () {
        this.newItem = {}
        this.newItem.title = null
      },

    }

  }
</script>
